.scroller::-webkit-scrollbar {
  width: 0.5em;
  background-color: #ebeff0;
}

.scroller::-webkit-scrollbar-thumb {
  background: radial-gradient(#4185cd, #165da7);
  height: 60%;
  border: 0.01em solid #ebeff0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1b436d;
}

.scroller:hover {
  scrollbar-width: unset;
}

/*Shine Effect*/

.effect-shine:hover {
  mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  -webkit-mask-size: 200%;
  animation: shine 1.5s infinite;
}

.effect-shine-static {
  mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  -webkit-mask-size: 200%;
  animation: shine 1.2s infinite;
}

.btn:hover {
  mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-image: linear-gradient(-75deg,
      rgba(0, 0, 0, 0.6) 30%,
      #000 50%,
      rgba(0, 0, 0, 0.6) 70%);
  mask-size: 200%;
  -webkit-mask-size: 200%;
  animation: shine 1.4s infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

/*Shine Effect*/

/*Highlight Effect*/
.highlight {
  background-image: linear-gradient(#a5bfe2, #a5bfe2);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2px;
  transition: background-size 0.6s, background-position 0.5s ease-in-out 0.5s;
}

h1:hover>.highlight {
  color: #000000;
  background-size: 100% 100%;
  background-position: 0% 0%;
  transition: background-position 0.6s, background-size 0.5s ease-in-out 0.5s;
  border-radius: 4px;
}

h1:hover>.highlight::after {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/*Highlight Effect*/

.blue-section {
  background: linear-gradient(-45deg, #165da7, #387cc5, #165da7, #387cc5);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  padding-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
  border-radius: 6px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.light-text {
  color: whitesmoke;
}

.blue-section>div>div>p {
  font-weight: 500;
}

.gap {
  gap: 2px;
}

/**/

.wave {
  animation: primary 5s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }

  to {
    left: -100%;
  }
}

.carousel-wrapper .carousel-container:before {
  content: "";
  float: left;
  padding-bottom: 19.25%;
}

.carousel-wrapper .carousel-container img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-wrapper .carousel-container img.carousel-image-01 {
  transform: translate3d(0%, 0, 0);
}

.carousel-wrapper .carousel-container img.carousel-image-02 {
  transform: translate3d(100%, 0, 0);
}

@media (max-width: 791px) {
  .carousel-wrapper .carousel-container img.carousel-image-02 {
    transform: translate3d(99.82%, 0, 0);
  }

  .para-desc {
    max-width: 350px;
  }
}

@media (max-width: 491px) {
  .carousel-wrapper .carousel-container img.carousel-image-02 {
    transform: translate3d(99.65%, 0, 0);
  }

  .para-desc {
    max-width: 350px;
  }
}

/* .title {
    transition: 0.15s ease-in;
}

.title:hover {
    text-shadow:
        1px 1px 0 #9f9f9f;
} */

.shadow-logo {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

a:hover {
  cursor: pointer;
}

.logo-size {
  position: relative;
  width: 20px;
}

.price-container {
  background-color: #eef1fd;
  position: relative;
  overflow: hidden;
}

.price-box {
  display: sticky;
  position: relative;
  width: auto;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20rem;
  animation: scrolling 23s linear infinite;
}

@keyframes scrolling {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    -moz-transform: translateX(120%);
    -webkit-transform: translateX(120%);
    transform: translateX(120%);
  }
}

.negative-price {
  color: red;
}

.positive-price {
  color: green;
}

.currency-id {
  font-size: small;
  color: #3c4858;
}

.price-styling {
  color: #3c4858;
  font-size: 1rem;
  z-index: 2;
}

.box-styling {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.inner-box-styling {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.change-styling {
  font-size: 1rem;
  z-index: 2;
}

@media (max-width: 1201px) {
  #topnav .navigation-menu>li {
    margin: 0 0px;
  }

  .price-box {
    gap: 2rem;
    animation: scrolling 20s linear infinite;
  }
}

@media (max-width: 401px) {
  .price-box {
    gap: 3rem;
    animation: scrolling 13s linear infinite;
  }
}

.sevices-text {
  font-size: larger;
}

/**/

#section-1 {
  display: block;
}

#section-1 h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body ul,
body li {
  margin: 0;
  padding: 0;
}

#section-1 h1 {
  font-weight: 700;
  text-transform: uppercase;
}

#section-1 h2 {
  font-size: 1.875em;
  font-weight: 700;
  text-transform: uppercase;
}

#section-1 h3 {
  font-family: "Kaushan Script", cursive;
  font-size: 1.5em;
  font-weight: 400;
}

#section-1 h4 {
  font-size: 0.875em;
  font-weight: 400;
  text-transform: uppercase;
}

#section-1 p {
  line-height: 150%;
  font-family: Roboto, sans-serif;
  font-size: 0.9375em;
  color: #999;
}

#section-1 li {
  list-style-type: none;
}

#section-1 a {
  text-decoration: none;
}

.bg-wrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8));
}

.section-header {
  padding-top: 5.625em;
  text-align: center;
}

.section-header h3 {
  margin-bottom: 0.5em;
}

.section-header .line-red {
  margin: 2.5em auto 2.9375em auto;
}

.line-red {
  display: block;
  width: 4em;
  height: 0.1875em;
  margin: 0 auto;
  background: #f38181;
}

.main-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  color: #fff;
  background: transparent;
}

.main-header .header-wrapper {
  display: block;
  width: 70%;
  margin: 0 auto;
  padding-top: 1em;
}

.main-header .header-wrapper .main-logo {
  float: left;
  font-weight: 700;
  font-size: 1.875em;
}

.main-header .header-wrapper .main-menu {
  float: right;
  text-align: center;
}

.main-header .header-wrapper .main-menu li {
  display: inline-block;
  padding: 0.75em 1.875em;
}

.main-header .header-wrapper .main-menu li a {
  padding-bottom: 0.75em;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.875em;
}

.main-header .header-wrapper .main-menu li a:hover {
  color: #fce38a;
  border-bottom: 0.1875em solid #fce38a;
  transition: 0.1s;
}

.main-header .header-wrapper .main-menu li a span {
  font-size: 1.2em;
}

.main-header .header-wrapper .main-menu li:last-child {
  padding: 0.75em 0;
}

.main-header .header-wrapper .main-menu li:nth-child(6) a:hover,
.main-header .header-wrapper .main-menu li:last-child a:hover {
  border: none;
}

#section-1 {
  height: 38em;
  color: #fff;
  background-color: #222;
}

#section-1 .content-slider {
  position: relative;
  width: 100%;
  height: 100%;
}

#section-1 .content-slider input {
  display: none;
}

#section-1 .content-slider .slider {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: hidden;
}

#section-1 .content-slider .slider .banner {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
  width: inherit;
  height: inherit;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: all 0.5s ease;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper {
  height: 100%;
  padding-top: 6em;
  box-sizing: border-box;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
  padding-bottom: 0.3em;
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-size: 2.5em;
  text-transform: none;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
  font-size: 5em;
  line-height: 100%;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
  display: block;
  width: 4em;
  height: 0.1875em;
  margin: 2.5em auto;
  background: #fff;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button {
  padding-bottom: 5em;
  z-index: 15 !important;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button a {
  padding: 0.5em 2em;
  text-align: center;

  font-size: 0.875em;
  color: #fff;
  text-transform: uppercase;
  border: 0.1875em solid #fff;
}

#section-1 .content-slider .slider .banner .banner-inner-wrapper .learn-more-button a:hover {
  color: #c1c1c1;
  border-color: #c1c1c1;
  transition: 0.3s;
}

#section-1 .content-slider nav {
  /* background-color: rgba(0, 0, 0, .2); */
  position: absolute;
  bottom: 0em;
  width: 100%;
  z-index: 10;
  text-align: center;
  padding-top: 10px;
}

#section-1 .content-slider nav .controls {
  display: block;
  width: 70%;
  margin: 0 auto;
  color: #fff;
}

#section-1 .content-slider nav .controls label {
  position: relative;
  display: inline-block;
  width: 20%;
  height: 3.1em;
  overflow: hidden;
  margin: 0 1em;
  padding-top: 1em;
  text-align: left;
  text-transform: uppercase;

  font-size: 1em;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
}

#section-1 .content-slider nav .controls label .progressbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #fff;
  z-index: 100;
  border-radius: 3px;
}

#section-1 .content-slider nav .controls label .progressbar .progressbar-fill {
  position: inherit;
  width: inherit;
  height: inherit;
  margin-left: -100%;
  background: #202942;
  border-radius: 3px;
}

#section-1 .content-slider nav .controls label span {
  font-size: 1.4em;
  font-weight: 700;
}

#section-1 .content-slider #banner1:checked~.slider #top-banner-1,
#section-1 .content-slider #banner2:checked~.slider #top-banner-2,
#section-1 .content-slider #banner3:checked~.slider #top-banner-3,
#section-1 .content-slider #banner4:checked~.slider #top-banner-4 {
  opacity: 1;
  z-index: 1;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1),
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2),
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3),
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) {
  cursor: default;
  color: #fff;
  transition: all 0.5s;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1) .progressbar,
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2) .progressbar,
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3) .progressbar,
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) .progressbar {
  background: #fff;
}

#section-1 .content-slider #banner1:checked~nav label:nth-of-type(1) .progressbar-fill,
#section-1 .content-slider #banner2:checked~nav label:nth-of-type(2) .progressbar-fill,
#section-1 .content-slider #banner3:checked~nav label:nth-of-type(3) .progressbar-fill,
#section-1 .content-slider #banner4:checked~nav label:nth-of-type(4) .progressbar-fill {
  animation: progressBarFill 5s linear;
}

@keyframes progressBarFill {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1920px) {
  body {
    font-size: 21px;
  }

  .main-header .header-wrapper {
    width: 57%;
  }

  #section-1 {
    height: 46em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 12em;
  }
}

@media only screen and (max-width: 1919px) {
  body {
    font-size: 20px;
  }

  .main-header .header-wrapper {
    width: 60%;
  }

  #section-1 {
    height: 38em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 11em;
  }
}

@media only screen and (max-width: 1680px) {
  body {
    font-size: 18px;
  }

  .main-header .header-wrapper {
    width: 65%;
  }

  #section-1 {
    height: 40em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 9em;
  }
}

@media only screen and (max-width: 1366px) {
  body {
    font-size: 16px;
  }

  .main-header .header-wrapper {
    width: 70%;
  }
}

@media only screen and (max-width: 1120px) {
  .main-header .header-wrapper .main-menu li {
    padding: 0.75em 1.4em;
  }

  /* #section-1 {
        height: 35em;
        margin-top: 105px;
    } */

  #section-1 .content-slider nav {
    bottom: -0.2em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 7em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 2em auto;
  }
}

@media only screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }

  .main-header .header-wrapper .main-menu li {
    padding: 0.75em 1.3em;
  }

  #section-1 .content-slider nav {
    bottom: 0;
  }

  #section-1 .content-slider nav .controls {
    width: 80%;
  }
}

@media only screen and (max-width: 860px) {
  .main-header .header-wrapper .main-menu li {
    padding: 0.75em 0.9em;
  }

  /* #section-1 {
        height: 29em;
    } */

  /* #section-1 .content-slider nav {
        bottom: -1em;
    } */

  #section-1 .content-slider nav .controls {
    width: 90%;
    /* padding-bottom: 1rem; */
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
    font-size: 2em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
    font-size: 4.5em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper {
    padding-top: 5em;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .main-header .header-wrapper .main-menu li {
    padding: 0.75em 0.5em;
  }

  #section-1 {
    height: 27em;
  }

  #section-1 .content-slider nav .controls {
    width: 100%;
  }

  #section-1 .content-slider nav .controls label {
    width: 19%;
    font-size: 0.8em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 1.7em auto;
  }
}

@media only screen and (max-width: 650px) {
  .main-header .header-wrapper {
    width: 95%;
  }
}

@media only screen and (max-width: 480px) {
  .main-header .header-wrapper {
    width: 97%;
  }

  .main-header .header-wrapper .main-logo {
    display: none;
  }

  .main-header .header-wrapper .main-menu li {
    padding: 0.2em 0.3em;
  }

  .main-header .header-wrapper .main-menu li:last-child,
  .main-header .header-wrapper .main-menu li:nth-child(6) {
    padding: 0.2em 0.7em;
  }

  #section-1 {
    height: 30em;
  }

  /* #section-1 .content-slider nav {
        bottom: -0.5em;
    } */

  #section-1 .content-slider nav .controls label {
    width: 40%;
    font-size: 0.7em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h2 {
    font-size: 1.5em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper h1 {
    font-size: 3em;
  }

  #section-1 .content-slider .slider .banner .banner-inner-wrapper .line {
    margin: 1.7em auto;
  }
}

.animated-bg {
  background: linear-gradient(45deg,
      #6ea3dc,
      #165da7,
      #104884,
      #6ea3dc,
      #165da7,
      #104884);
  background-size: 10000px 100%;
  animation: gradient 15s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

.area {
  background: #165da7;
  width: 100%;
  height: inherit;
  position: absolute;
  top: 0;
  padding-bottom: 28rem;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  /* background: rgb(255, 255, 255); */
  background: rgba(255, 255, 255, 0.3);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.dropshadow {
  filter: drop-shadow(15px 16px 5px #222);
}

@media only screen and (max-width: 480px) {
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .mobpic {
    max-width: 55%;
  }
}

.landing-image {
  position: inherit;
  z-index: -1;
  left: 0;
  filter: opacity(0.8) blur(3px);
  width: 100%;
  height: 120%;
  transform: translate(0%, -10%);
}

@media only screen and (max-width: 600px) {
  .landing-image {
    width: 100%;
    height: 100%;
    transform: translate(0%, 0%);
  }
}

@media only screen and (min-width: 601px) {
  #bg1 {
    background-image: url("../images/NEW/cryptoexchange.png");
    background-size: cover;
  }

  #bg2 {
    background-image: url("../images/NEW/cryptopayment.png");
    background-size: cover;
  }

  #bg3 {
    background-image: url("../images/NEW/cryptoapp1.png");
    background-size: cover;
  }
}

@media only screen and (max-width: 1100px) {
  #bg3 {
    background-image: url("../images/NEW/cryptoapp2.png");
    background-size: cover;
  }
}

@media only screen and (max-width: 600px) {
  #bg1 {
    background-image: url("../images/NEW/cryptoexchange1.png");
    background-size: cover;
  }

  #bg2 {
    background-image: url("../images/NEW/cryptopayment1.png");
    background-size: cover;
  }

  #bg3 {
    background-image: url("../images/NEW/cryptoapp11.png");
    background-size: cover;
  }
}

.mt-106 {
  margin-top: 106px;
}

.slider-height {
  height: fit-content;
}

.slider-height>div>div {
  margin-bottom: 0;
}

.slider>.banner>.banner-inner-wrapper {
  color: #ebeff0;
}

.eduimg1 {
  display: block;
  margin-inline: auto;
  width: 80%;
}

@media (min-width: 1101px) {
  .education {
    max-width: 80vw;
  }
}

@media (max-width: 900px) {
  .education {
    max-width: 90vw;
  }
}

@media (max-width: 400px) {
  .education {
    max-width: 95vw;
  }
}

@media (max-width: 400px) {
  .svvg {
    display: none;
  }
}

.svvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* filter: opacity(0.06); */
  mix-blend-mode: soft-light;
}

.svvvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: blur(10px);
}

.container {
  position: relative;
  z-index: 1;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.out-top {
  animation: rotate 20s linear infinite;
  transform-origin: 13px 25px;
}

.in-top {
  animation: rotate 10s linear infinite;
  transform-origin: 13px 25px;
}

.out-bottom {
  animation: rotate 25s linear infinite;
  transform-origin: 84px 93px;
}

.in-bottom {
  animation: rotate 15s linear infinite;
  transform-origin: 84px 93px;
}

.content>h5 {
  color: #000;
}

a>.flag-radius {
  border-radius: 3px;
  transition: 0.25s;
  filter: contrast(0.4);
}

a>.flag-radius:hover {
  transform: scale(1.2);
  filter: contrast(1);
}

@media (min-width: 900px) {
  .edutext {
    margin-inline: 10%;
  }
}

.card-header {
  background-color: #e5eaff3a;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-250px * 3));
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(calc(-250px * 3));
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 3));
  }
}

.slider-partners {
  background-color: #ff000000;

  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 95%;
}

.slider-partners::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider-partners::before {
  left: 0;
  top: 0;
}

.slider-partners .slide-track {
  display: flex;
  width: 100%;
}

@media (min-width: 900px) {
  .slider-partners {
    height: 120px;
    margin-bottom: 15px;
  }

  .slider-partners::before,
  .slider-partners::after {
    background: linear-gradient(to right,
        rgba(238, 241, 253, 1) 0%,
        rgba(255, 255, 255, 0) 50%);
    content: "";
    height: 120px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slide-partner>img {
    height: 120px;
    border-radius: 6px;
    width: 120px;
  }

  .slider-partners .slide-partner {
    margin-right: 30px;
  }
}

@media (max-width: 900px) {
  .slider-partners {
    height: 90px;
    margin-bottom: 5px;
    border-radius: 6px;
  }

  .slider-partners::before,
  .slider-partners::after {
    background: linear-gradient(to right,
        rgba(238, 241, 253, 1) 0%,
        rgba(255, 255, 255, 0) 50%);

    content: "";
    height: 90px;
    position: absolute;
    width: 50px;
    z-index: 2;
  }

  .slide-partner>img {
    border-radius: 6px;
    height: 90px;
    width: 90px;
  }

  .slider-partners .slide-partner {
    margin-right: 15px;
  }
}

.slideLeft {
  animation: scrollLeft 40s linear infinite;
}

.slideRight {
  animation: scrollRight 40s linear infinite;
}

.text-white {
  color: white;
}

.logom {
  margin: 5px;
}

.erou::before {
  content: "-";
}

.text-container {
  transition: opacity 0.3s ease;
  /* Adjust the duration as needed */
}

.fade-out {
  opacity: 0;
}


.appic {
  min-width: 30%;
  max-width: 60%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}


@media (max-width: 700px) {
  .cluster {
    background-color: #ff0000c5 !important;
    font-weight: bold;
    border-radius: 50%;
    border-color: transparent;
  }
}

