
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&amp;display=swap');

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Poppins", sans-serif !important;
}

#topnav .navigation-menu > li > a {
    font-family: "Poppins", sans-serif !important;
}

.img-mw-60 {
    max-width: 60% !important;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2);
}

.img-mw-70 {
    max-width: 70% !important;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2);
}

.img-mw-85 {
    max-width: 85% !important;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.2);
}